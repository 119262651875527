import dayjs from 'dayjs'
import React, { useEffect, useState, Fragment, useCallback } from 'react';
import { Dialog, MenuItem, DialogContent, Fab, Alert, Tooltip, CircularProgress, Button, Badge, Grid, FormControl, InputLabel, Select, IconButton, Popover, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useLazyConstructionSiteDiaryQuery } from '../../api/graphqlApi';
// import { PDFDownloadLink } from '@react-pdf/renderer';
import { LoadingButton } from '@mui/lab';
import { databaseTimeToString, normalizeWeatherCode, stringToJson } from '../Utilities';
import { Info, LibraryBooks } from '@mui/icons-material';
// // import Document from './Document';
// import { pdfjs, Document } from 'react-pdf'

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.mjs`

export default function Diary({ timestamp, loading, disabled }) {
    
    const { CAMERAS, DEBUG, LAYOUT: { TITLE, DIARY: { AI } }, BASEURL } = window.conf;
    const { t } = useTranslation();

    const [featureDetailId, setFeatureDetailId] = useState(CAMERAS?.length ? CAMERAS[0].featureDetailId : 0);
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState(null);
    const [getConstructionSiteDiary] = useLazyConstructionSiteDiaryQuery();
    const [open, setOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ type: 'error', message: null });
    const [windowHeight, setWindowHeight] = useState(window.innerHeight * window.visualViewport?.scale || 1)
    const [anchorPopover, setAnchorPopover] = useState(null)
    
    const contentHeight = windowHeight - 234
    const databaseDateFormat = 'YYYY-MM-DD';
    const dateDatabase = timestamp.format(databaseDateFormat);
    const openPopover = Boolean(anchorPopover)
    const idPopover = openPopover ? 'simple-popover' : undefined
    const updateWindowDimensions = () => setWindowHeight(window.innerHeight * window.visualViewport?.scale || 1)
    const enableAI = AI

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions)
        return () => window.removeEventListener('resize', updateWindowDimensions)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (open) {
            handleGetConstructionSiteDiary()
        }
    }, [open, featureDetailId]) // eslint-disable-line react-hooks/exhaustive-deps

    const onOpen = () => {
        setFeatureDetailId(CAMERAS?.length ? CAMERAS[0].featureDetailId : 0)
        setOpen(true)
    }

    const onClose = () => {
        setOpen(false)
    }

    const onOpenPopover = (event) => {
        setAnchorPopover(event.currentTarget)
    }
    
    const onClosePopover = () => {
        setAnchorPopover(null)
    }

    const handleCameraChange = (event) => {
        setFeatureDetailId(event.target.value)
    }

    const handleGetConstructionSiteDiary = useCallback(async() => {
        try {
            setIsLoading(true);
            setResult(null);
            setAlertMessage({
                type: 'error',
                message: null,
            });
            const { data } = await getConstructionSiteDiary({
                featureDetailId: featureDetailId,
                date: dateDatabase,
            });
            if (!data || data.length < 1) {
                setAlertMessage({
                    type: 'error',
                    message: t('msg.no_data')
                });
                setIsLoading(false);
            } else {
                setAlertMessage({
                    type: 'success',
                    message: '',
                });
                setResult(mappingData(data[0]))
            }
            setIsLoading(false);
        } catch(error) {
            setAlertMessage({
                type: 'error',
                message: t('msg.exe_error')
            });
            setIsLoading(false);
            console.error(error)
        }
    }, [getConstructionSiteDiary, open, featureDetailId]) // eslint-disable-line react-hooks/exhaustive-deps

    const mappingData = (raw) => {
        
        const camera = CAMERAS.find((camera) => camera.featureDetailId === featureDetailId)
        const date = raw.date && raw.date !== "None" ? dayjs(raw.date).format(t('date.format.full')) : ""
        const title = TITLE + (camera?.name && `, ${camera?.name}`) + (date && ` ${t('date.on')} ${t(`weather.week_days.${dayjs(raw.date).day()}`)}, ${t('date.the')} ${date}`)
        const internalInfo = raw.internalInfo && raw.internalInfo !== 'None' ? stringToJson(raw.internalInfo) : null
        const lastUpdate = internalInfo?.lastUpdate && internalInfo?.lastUpdate !== "None" ? dayjs(internalInfo?.lastUpdate).format(t('date.format.full_date_time')) : ""
        const forecast = raw.weatherForecast && raw.weatherForecast !== 'None' ? stringToJson(raw.weatherForecast) : []
        const summary = internalInfo?.weatherChanges && internalInfo?.weatherChanges !== 'None' ? internalInfo?.weatherChanges : []
        const vehicles = raw.vehicles && raw.vehicles !== 'None' ? stringToJson(raw.vehicles) : []
        const fileName = raw.date && raw.date !== "None" ? `${t('events.construction_diary.construction_diary')}_${raw.date}.pdf` : `${t('events.construction_diary.construction_diary')}.pdf`
        let alerts = raw.weatherAlerts && raw.weatherAlerts !== 'None' ? stringToJson(raw.weatherAlerts) : []
        const reportPath = `${BASEURL}cam${camera.id + 1}/reports/${raw.date}.jpg`

        if (alerts.length === 1 && alerts[0]?.[1]?.length === 0) { // if there is only one alert and it is empty
            alerts = []
        }
        
        const res = {
            title: title,
            lastUpdate: lastUpdate,
            weather: {
                sunrise: raw.sunrise && raw.sunrise !== "None" ? databaseTimeToString(raw.sunrise) : "",
                sunset: raw.sunset && raw.sunset !== "None" ? databaseTimeToString(raw.sunset) : "",
                min_temperature: raw.minTemp !== null && raw.minTemp !== "None" ? parseFloat(raw.minTemp.toFixed(0)) : "",
                max_temperature: raw.maxTemp !== null && raw.maxTemp !== "None" ? parseFloat(raw.maxTemp.toFixed(0)) : "",
                average_temperature: raw.avgTemp !== null && raw.avgTemp !== "None" ? parseFloat(raw.avgTemp.toFixed(0)) : "",
                max_wind_speed: raw.maxWindSpeed !== null && raw.maxWindSpeed !== "None" ? parseFloat(raw.maxWindSpeed.toFixed(2)) : "",
                min_wind_speed: raw.minWindSpeed !== null && raw.minWindSpeed !== "None" ? parseFloat(raw.minWindSpeed.toFixed(2)) : "",
                max_humidity: raw.maxHumidity !== null && raw.maxHumidity !== "None" ? raw.maxHumidity : "",
                min_humidity: raw.minHumidity !== null && raw.minHumidity !== "None" ? raw.minHumidity : "",
                summary: summary?.map(elem => 
                    `${t("date.from")} ${elem[0].replace("-", ":")}${t("date.oclock")}: ${t(`weather.conditions.${normalizeWeatherCode(elem[1])}`)}` 
                ),
                alerts: alerts?.map(elem =>
                    `${elem[0].replace("-", ":")}${t("date.oclock")}:\n${elem[1].length > 0 ? 
                        elem[1].map(alert => "- " + t(`weather.alerts.${alert.event.replace(":idx","")}.${alert.description}`)).join('\n') : 
                        t(`weather.no_alerts`)}`
                ),
                forecast: forecast?.map((elem, index) => ({ 
                    ...elem, 
                    day: t(`weather.week_days.${dayjs(raw.date).add(index + 1, 'day').day()}`),
                    description: t(`weather.conditions.${normalizeWeatherCode(elem.forecastCode)}`)
                }))
            },
            events: {
                start_of_work: raw.startWork && raw.startWork !== "None" ? databaseTimeToString(raw.startWork) : "",
                end_of_work: raw.endWork && raw.endWork !== "None" ? databaseTimeToString(raw.endWork) : "",
                maxPeople: raw.maxPeople !== null && raw.maxPeople !== "None" ? raw.maxPeople : "",
                maxPeopleTime: raw.maxPeopleTime && raw.maxPeopleTime !== "None" ? databaseTimeToString(raw.maxPeopleTime) : "",
                vehicles: vehicles
            },
            filename: fileName,
            reportPath: reportPath,
            enableAI: enableAI
        }

        DEBUG && console.log("getConstructionSiteDiary", raw, "mapped to", res)

        return res
    }

    const StyledBadge = styled(Badge)(({ theme, loading }) => ({
        '& .MuiBadge-badge': {
            right: -18,
            top: 30,
            border: `1px solid ${theme.palette.background.paper}`,
            backgroundColor: loading ? theme.palette.action.disabledBackground : "#FF6600"
        }
    }))

    return (
        <Fragment>
            <Tooltip title={t('events.construction_diary.create_diary')} placement="top" arrow>
                <span>
                    <Fab
                        area-label="Construction Diary"
                        size="medium"
                        color="primary"
                        onClick={onOpen}
                        disabled={loading || disabled}
                        sx={{ mr: '2px', ml: '2px', width: '40px', height: '40px' }}
                    >
                        {disabled ?
                        <LibraryBooks /> :
                        <StyledBadge 
                            badgeContent="BETA" 
                            loading={loading ? 1 : 0}
                        >
                            <LibraryBooks />
                        </StyledBadge>}
                    </Fab>
                </span>
            </Tooltip>

            <Dialog 
                open={open} 
                onClose={onClose} 
                aria-labelledby="responsive-dialog-title" 
                maxWidth="md"
            >

                <DialogContent sx={{ p: 2 }}>

                    <Grid container>

                        <Grid item xs={12} sx={{ pb: 0 }} /> {/* An empty grid item. A trick to fix the lable layout issue on FormControl */}

                        {CAMERAS.length > 1 && (
                            <Grid item xs={12} sx={{ pb: 2 }}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id='selectCameraLabel'>{t("events.camera")}</InputLabel>
                                    <Select
                                        labelid='selectCameraLabel'
                                        label={t("events.camera")}
                                        value={featureDetailId}
                                        onChange={handleCameraChange}
                                        disabled={isLoading}
                                    >
                                        {CAMERAS.map(camera => (
                                            <MenuItem
                                                key={camera.id}
                                                value={camera.featureDetailId}
                                            >
                                                {camera.name !== "" ? camera.name : `${t("events.camera")} ${camera.id + 1}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}

                        {result && (
                            <Grid 
                                item 
                                xs={12} 
                                sx={{ 
                                    mb: 2,
                                    border: '1px solid',
                                    borderColor: 'rgba(196,196,196)',
                                    borderRadius: '4px',
                                    height: contentHeight,
                                    overflow: 'auto'
                                }}
                            >
                                {/* <Document data={result} />
                                <Document file="https://www.orimi.com/pdf-test.pdf" /> */}
                            </Grid>
                        )}

                        {isLoading && (
                            <Grid 
                                item 
                                xs={12} 
                                sx={{ 
                                pb: 2,
                                width: '100%', 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center' 
                            }}>
                                <CircularProgress />
                            </Grid>
                        )}

                        {alertMessage?.message && (
                             <Grid item xs={12} sx={{ pb: 2 }}>
                                <Alert
                                    variant="filled"
                                    severity={alertMessage?.type}
                                    sx={{ alignItems: 'center' }}
                                >
                                    {alertMessage?.message}
                                </Alert>
                            </Grid>
                        )}

                        <Grid
                            item 
                            xs={12}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Button onClick={onClose} variant="outlined">
                                {t('actions.close')}
                            </Button>

                            {result && 
                            enableAI && (
                                <IconButton
                                    aria-label={idPopover}
                                    onClick={onOpenPopover}
                                    color="primary"
                                >
                                    <Info />
                                </IconButton>
                            )}
{/* 
                            {result ? (
                                <PDFDownloadLink
                                    fileName={result.filename}
                                    document={<Document data={result} pdf={true} />}
                                >
                                    {({ loading, error }) => (
                                        <LoadingButton 
                                            loading={loading} 
                                            disabled={!!error} 
                                            variant="outlined"
                                        >
                                            {t('actions.download')}
                                        </LoadingButton>
                                    )}
                                </PDFDownloadLink>
                            ) : (
                                <Button disabled variant="outlined">
                                    {t('actions.download')}
                                </Button>
                            )} */}

                        </Grid>

                    </Grid>
                </DialogContent>
            </Dialog>

            <Popover
                id={idPopover}
                open={openPopover}
                anchorEl={anchorPopover}
                onClose={onClosePopover}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            >
                <Typography sx={{ 
                    p: 2, 
                    whiteSpace: "pre-line", 
                    userSelect: "none" 
                }}>
                    {t('events.construction_diary.info')}
                </Typography>
            </Popover>
        </Fragment>
    )
}